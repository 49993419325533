body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden !important;
  background-image: linear-gradient(
    110.3deg,
    rgba(73, 93, 109, 1) 4.3%,
    rgba(49, 55, 82, 1) 96.7%
  );
}

.content {
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.gz-layer {
  background: rgba(255, 255, 255, 0.1);
}

/*  */
